import IconCheck from 'assets/icons/icon-check.svg?component';
import IconChevronDown from 'assets/icons/icon-chevron-down.svg?component';
import IconChevronUp from 'assets/icons/icon-chevron-up.svg?component';

export default defineComponent({
  name: 'Dropdown',
  props: {
    options: {
      type: Array<{ id: number | string; text: string; caption?: string }>,
      required: true,
    },
    modelValue: {
      required: true,
      type: [Number, String],
    },
    onChange: {
      required: true,
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const isOpen = ref(false);

    const selected = ref(props.modelValue);

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const selectOption = (option: (typeof props.options)[number]) => {
      selected.value = option.id;
      props.onChange(option.id);
      toggleDropdown();
      event?.preventDefault();
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        selected.value = newValue;
      }
    );

    return { isOpen, selected, toggleDropdown, selectOption };
  },
  render(): VNode {
    const selectedOption = this.options.find(
      (option) => option.id === this.selected
    );
    return (
      <div class="relative flex flex-col">
        <button
          class={`border-1 border-rb-green text-rb-dark-green disabled:bg-rb-lighter-gray flex flex-row gap-2 px-4 py-3 font-medium disabled:cursor-not-allowed ${
            this.isOpen ? 'rounded-t-sm' : 'rounded-sm'
          }`}
          disabled={this.disabled}
          onClick={this.toggleDropdown}
        >
          {this.$slots.icon?.()}

          <span class="flex-1 text-left">
            {
              <div class="flex flex-1 justify-between">
                <span>{selectedOption?.text}</span>

                <span class="text-rb-gray text-sm">
                  {selectedOption?.caption}
                </span>
              </div>
            }
          </span>
          {!this.disabled &&
            (this.isOpen ? (
              <IconChevronUp aria-hidden="true" class="h-6 w-6" />
            ) : (
              <IconChevronDown aria-hidden="true" class="h-6 w-6" />
            ))}
        </button>
        {this.isOpen && (
          <ul class="border-1 border-rb-green absolute top-full z-10 w-full">
            {this.$props.options.map((option) => (
              <li
                class="hover:bg-rb-lighter-gray aria-selected:bg-rb-light-yellow flex cursor-pointer flex-row gap-2 bg-white px-4 py-3 font-medium last:rounded-b-sm"
                aria-selected={option.id === this.selected}
                key={option.id}
                onClick={() => this.selectOption(option)}
              >
                <div class="basis-1/12">
                  {option.id === this.selected && (
                    <IconCheck
                      aria-hidden="true"
                      class="text-rb-light-yellow h-6 w-6"
                    />
                  )}
                </div>
                <div class="flex flex-1 justify-between">
                  <span>{option.text}</span>
                  <span class="text-rb-gray text-sm">{option.caption}</span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  },
});
